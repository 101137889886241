<template>
  <div>
    <b-card title="Welcome to SecurityInfinity scanner dashboard 🚀">
      <b-card-text>Signup to our Prime plan for complete scan including cloud security. Prime plan has ultimate vulnerability detection which uncovers all vulnerabilities.</b-card-text>

      <b-card-text>Get a website security assessment that covers all your internet assets.</b-card-text>
        

       <b-card-text>
       SecurityInfinity scanner helps you figure out just how vulnerable your website or company is. 
      </b-card-text>

    </b-card>

    
    <b-card title="Signup now 🔒">

      <b-button  href="https://securityinfinity.com/auth/signup"

            variant="outline-primary"
          >
            Signup Now
          </b-button>
    <br><br>
    <b-card-text>Securityinfinity scanner will find loopholes including any emerging threats that may be affecting your current website infrastructure or assets. 
    </b-card-text>

      <b-card-text>Website security is your best defense against attacks, we find you loopholes in your online asset that can be exploited.</b-card-text>


    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BButton } from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
  },
}
</script>

<style>
 .card-text{
    font-size:18px;
}


</style>
